
import { defineComponent, ref, computed, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { ColumnProps } from 'ant-design-vue/es/table/interface';
import { Modal, message } from 'ant-design-vue';
import moment from 'moment';
import Title from '@/views/LocalRegistration/components/title.vue';
import PublishDate from '@/views/LocalRegistration/FocusAreaSet/FocusAreaRuleSet/PublishDate.vue';
import FocusAreaSpecialRule from '@/views/LocalRegistration/FocusAreaSet/FocusAreaRuleSet/FocusAreaSpecialRule.vue';
import BuSelector from '@/views/LocalRegistration/components/BuSelector.vue';
import ApplicableTypeClassSelector from '@/views/LocalRegistration/components/ApplicableTypeClassSelector2.vue';
import {
    publishDateList as publishDateListAPI,
    getAreaRuleList as getAreaRuleListAPI,
    getCategoryList as getCategoryListAPI,
    saveAreaRuleInfo as saveAreaRuleInfoAPI,
} from '@/API/localRegistration';
import {
    PublishDateVersionType,
    PublishDateVersion,
    AreaRuleCategory,
    AreaRule,
    SpecialRule,
    AreaRuleParams,
} from '@/views/LocalRegistration/type';

enum Mode {
    VIEW,
    EDIT,
}

export default defineComponent({
    components: {
        Title,
        PublishDate,
        FocusAreaSpecialRule,
        BuSelector,
        ApplicableTypeClassSelector,
    },
    setup() {
        const router = useRouter();
        const { state } = useStore();

        const publishDateVersions = ref<PublishDateVersion[]>([]);
        const publishDateVersion = ref<string>();
        const categories = ref<AreaRuleCategory[]>([]);
        const selectedIndex = ref<number>();

        const mode = computed(() => {
            const selectedVersion = publishDateVersions.value.find(
                (item) => item.date === publishDateVersion.value
            );
            if (selectedVersion) {
                return selectedVersion.type === PublishDateVersionType.HISTORY
                    ? Mode.VIEW
                    : Mode.EDIT;
            }
            return Mode.VIEW;
        });

        const columns = computed(() => {
            const columns: ColumnProps[] = [
                {
                    title: 'BU',
                    slots: {
                        customRender: 'BU',
                    },
                },
                {
                    title: 'Applicable type class',
                    slots: {
                        customRender: 'ApplicableTypeClass',
                    },
                },
                {
                    title: 'Focus area basic rule',
                    slots: {
                        customRender: 'FocusAreaBasicRule',
                    },
                },
                {
                    title: 'Category',
                    slots: {
                        customRender: 'Category',
                    },
                },
                {
                    title: 'Focus area special rule',
                    slots: {
                        customRender: 'FocusAreaSpecialRule',
                    },
                },
            ];

            if (mode.value === Mode.EDIT) {
                columns.push({
                    title: 'Operation',
                    slots: {
                        customRender: 'Operation',
                    },
                });
            }

            return columns;
        });

        const data = ref<AreaRule[]>([]);

        const visiblePublishDateDrawer = ref(false);

        const handleShowPublishDateDrawer = () => {
            visiblePublishDateDrawer.value = true;
        };

        const visibleFocusAreaRuleSetDrawer = ref(false);

        const handleShowFocusAreaRuleSetDrawer = (index: number) => {
            selectedIndex.value = index;
            visibleFocusAreaRuleSetDrawer.value = true;
        };

        const search = async () => {
            const userBuList = state.user.buTreeList.filter((item: {nameEn: string}) => ['MBPC', 'AMG', 'VAN'].includes(item.nameEn))
              .map((item: {nameEn: string}) => item.nameEn)

            data.value = (await getAreaRuleListAPI(
                publishDateVersion.value as string
            )).filter(item => userBuList.includes(item.bu));
        };

        const init = () => {
            publishDateListAPI().then((data) => {
                publishDateVersions.value = data
                    .map((date) => {
                        return {
                            date: moment(date).format('YYYY-MM-DD'),
                            type: PublishDateVersionType.HISTORY,
                        };
                    })
                    .sort((a, b) => {
                        return a.date < b.date ? 1 : -1;
                    });
                if (publishDateVersions.value.length) {
                    publishDateVersion.value =
                        publishDateVersions.value[0].date;
                }
            });
            getCategoryListAPI().then((data) => {
                categories.value = data;
            });
        };

        const handleAdd = () => {
            data.value.push({
                category: '0',
                focusAreaBasic: '0',
                focusAreaExtend: '0',
                focusAreaSpecial: '0',
                publishDate: publishDateVersion.value as string,
                status: '0',
            });
        };

        const handleDelete = (index: number) => {
            data.value.splice(index, 1);
        };

        const handleSubmitSpecialRule = (specialRules: SpecialRule[]) => {
            if (
                selectedIndex.value !== undefined &&
                selectedIndex.value < data.value.length
            ) {
                data.value[selectedIndex.value].specialRule = specialRules;
            }
        };

        const handleCancel = () => {
            router.push({ name: 'Focus Area Set Home' });
        };

        const handleSave = () => {
            const uniqueKeys: string[] = [];
            const params: AreaRuleParams = [];
            for (const item of data.value) {
                if (!item.bu || !item.applicableTypeClass) {
                    continue;
                }
                const uniqueKey = `${item.bu}#${item.applicableTypeClass}`;
                if (uniqueKeys.includes(uniqueKey)) {
                    message.error('duplicated setting');
                    return;
                }
                uniqueKeys.push(uniqueKey);
                const selectCityList: {
                    code: string;
                    name: string;
                    provinceCode: string;
                    provinceName: string;
                }[] = [];
                item.specialRule?.forEach((specialRuleItem) => {
                    const selectedProvince = specialRuleItem.selectedProvince;
                    if (selectedProvince) {
                        specialRuleItem.selectedCities?.forEach(
                            (selectedCity) => {
                                selectCityList.push({
                                    provinceCode: selectedProvince,
                                    provinceName: specialRuleItem.selectedProvinceName as string,
                                    code: selectedCity,
                                    name:
                                        specialRuleItem.selectedCitiesName[
                                            selectedCity
                                        ],
                                });
                            }
                        );
                    }
                });
                params.push({
                    bu: item.bu,
                    applicableTypeClass: item.applicableTypeClass,
                    focusAreaBasic: '0',
                    focusAreaExtend: item.focusAreaExtend,
                    category: item.category,
                    publishDate: publishDateVersion.value as string,
                    focusAreaSpecial: '0',
                    status: '0',
                    selectCityList,
                });
            }

            Modal.confirm({
                title: 'Please confirm',
                content: "Can't edit rule after setting，please check if need",
                okText: 'Confirm',
                cancelText: 'Cancel',
                onOk: async () => {
                    await saveAreaRuleInfoAPI(params);
                    const version = publishDateVersions.value.find(
                        (item) => item.date === publishDateVersion.value
                    );
                    if (version) {
                        version.type = PublishDateVersionType.HISTORY;
                    }
                    search();
                },
            });
        };

        init();

        watch(publishDateVersion, (publishDate) => {
            if (!publishDate) {
                return;
            }
            search();
        });

        return {
            moment,
            Mode,
            mode,
            selectedIndex,
            publishDateVersions,
            publishDateVersion,
            categories,
            columns,
            data,
            visiblePublishDateDrawer,
            handleShowPublishDateDrawer,
            visibleFocusAreaRuleSetDrawer,
            handleShowFocusAreaRuleSetDrawer,
            handleAdd,
            handleDelete,
            handleSubmitSpecialRule,
            handleCancel,
            handleSave,
        };
    },
});
