
import { defineComponent, ref, watch } from 'vue'
import type { PropType } from 'vue'
import { Moment } from 'moment'
import moment from 'moment'
import useModel from '@/hooks/useModel'
import {
  PublishDateVersionType
} from '@/views/LocalRegistration/type'
import type {
  PublishDateVersion
} from '@/views/LocalRegistration/type'
export default defineComponent({
  emits: [
    'update:visible',
    'update:publishDateVersion',
    'update:publishDateVersions',
  ],
  props: {
    publishDateVersions: {
      type: Array as PropType<PublishDateVersion[]>,
      default: () => []
    },
    publishDateVersion: {
      type: String,
      default: ''
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  setup(props, {emit}) {
    
    const visibleModel = useModel(props, 'visible')
    const selectPublishDate = ref('')

    watch(() => props.publishDateVersion, (publishDate) => {
      selectPublishDate.value = publishDate
    })
    
    const date = ref<Moment>(moment())

    const handleClose = () => {
      visibleModel.value = false
    }

    const columns = ref([
      {
        title: 'Publish Date',
        dataIndex: 'date'
      }
    ])

    const changePublishDate = (selectRowKeys: string[]) => {
      if (selectRowKeys.length) {
        selectPublishDate.value = selectRowKeys[0]
      }
    }

    const disabledDate = (currentDate: Moment) => {
      if (currentDate.isBefore(moment(), 'day')) {
        return true
      }
      if (props.publishDateVersions.map(item => item.date).includes(currentDate.format('YYYY-MM-DD'))) {
        return true
      }
      return false
    }

    const handleAdd = () => {
      if (!date.value) {
        return
      }
      if (props.publishDateVersions.map(item => item.date).includes(date.value.format('YYYY-MM-DD'))) {
        return
      }
      const publishDateVersions = props.publishDateVersions
      publishDateVersions.push({
        date: date.value.format('YYYY-MM-DD'),
        type: PublishDateVersionType.NEW
      })
      publishDateVersions.sort((a, b) => {
        return a.date < b.date ? 1 : -1
      })
      selectPublishDate.value = date.value.format('YYYY-MM-DD')
      emit('update:publishDateVersions', publishDateVersions)
    }

    const handleConfirm = () => {
      emit('update:publishDateVersion', selectPublishDate.value)
      handleClose()
    }

    return {
      visibleModel,
      date,
      handleClose,
      columns,
      disabledDate,
      handleAdd,
      selectPublishDate,
      changePublishDate,
      handleConfirm
    }
  }
})
