
import { defineComponent, ref, watch, computed } from 'vue'
import type { PropType } from 'vue'
import { ColumnProps } from "ant-design-vue/es/table/interface"
import useModel from '@/hooks/useModel'
import Title from '@/views/LocalRegistration/components/title.vue'
import deepClone from '@/utils/deepClone'
import type {
  AreaRuleProvince,
  SpecialRule
} from '@/views/LocalRegistration/type'
import {
  getAreaRuleSelectedProvinces as getAreaRuleSelectedProvincesAPI,
  getAreaRuleSelectedCities as getAreaRuleSelectedCitiesAPI
} from '@/API/localRegistration'

export default defineComponent({
  components: {
    Title
  },
  emits: [
    'confirm'
  ],
  props: {
    logicId: {
      type: Number,
      default: 0
    },
    specialRule: {
      type: Array as PropType<SpecialRule[]>,
      default: () => []
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  setup(props, {emit}) {
    
    const visibleModel = useModel(props, 'visible')
    const provinceOptions = ref<AreaRuleProvince[]>([])

    const inEditing = computed(() => {
      return props.logicId === 0
    })

    const columns = computed(() => {
      const columns: ColumnProps[] = [
        {
          title: 'Province',
          slots: {
            customRender: 'Province'
          }
        },
        {
          title: 'City',
          slots: {
            customRender: 'City'
          }
        },
      ]
      if (inEditing.value) {
        columns.push({
          title: 'Operation',
          slots: {
            customRender: 'Operation'
          }
        })
      }

      return columns
    })

    const data = ref<SpecialRule[]>([])

    const handleAdd = () => {
      data.value.push({
        selectedCities: [],
        selectedCitiesName: {},
        cityOptions: []
      })
    }

    const handleDelete = (index: number) => {
      data.value.splice(index, 1)
    }

    const handleClose = () => {
      data.value = []
      visibleModel.value = false
    }

    const handleConfirm = () => {
      emit('confirm', data.value)
      handleClose()
    }

    const findProvinceIdByCode = (code: string) => {
      const province = provinceOptions.value.find(item => item.code === code)
      return province ? province.id : 0
    }

    /**
     * 根据省份获取城市
     */
    const getCities = async (index: number, logicId: number, provinceCode: string, reset = false) => {
      const cityOptions = await getAreaRuleSelectedCitiesAPI(logicId, findProvinceIdByCode(provinceCode), provinceCode)
      data.value[index].cityOptions = cityOptions
      if (!reset) {
        const selectedCities: string[] = []
        cityOptions.forEach(cityOption => {
          if (cityOption.isSelected === '1') {
            selectedCities.push(cityOption.code)
          }
        })
        data.value[index].selectedCities = selectedCities
      } else {
        data.value[index].selectedCities = []
      }
    }

    const handleSelectProvince = (index: number, option: {title: string; value: string}) => {
      data.value[index].selectedProvinceName = option.title
      getCities(index, props.logicId, option.value, true)
    }

    const handleSelectCity = (index: number, options: {title: string; value: string}[]) => {
      const selectedCitiesName = {}
      options.forEach(option => {
        selectedCitiesName[option.value] = option.title
      })
      data.value[index].selectedCitiesName = selectedCitiesName
    }

    // 显示时初始化数据
    watch(visibleModel, async (visible: any) => {
      if (!visible) {
        return false
      }
      provinceOptions.value = await getAreaRuleSelectedProvincesAPI(props.logicId)
      if (props.logicId !== 0) { // 查看历史版本
        data.value = []
        provinceOptions.value.forEach((provinceOption, index) => {
          if (provinceOption.isSelected === '1') {
            const item: SpecialRule = {
              selectedProvince: provinceOption.code,
              selectedProvinceName: provinceOption.name,
              selectedCities: [],
              selectedCitiesName: {},
              cityOptions: []
            }
            data.value.push(item)
            getCities(data.value.length - 1, props.logicId, provinceOption.code)
          }
        })
      } else { // 新建版本
        data.value = deepClone(props.specialRule)
      }
    })

    /**
     * 已选中的省份
     */
    const selectedProvices = computed(() => {
      return data.value.map(item => item.selectedProvince)
    })

    return {
      visibleModel,
      handleClose,
      columns,
      data,
      handleAdd,
      handleDelete,
      handleConfirm,
      provinceOptions,
      handleSelectProvince,
      handleSelectCity,
      selectedProvices,
      inEditing
    }
  }
})
