export interface ThirdPartyUploadSearchParams {
    bu?: string;
    beginTime?: string;
    endTime?: string;
    filenames?: string[];
    vin?: string;
}

export interface ThirdPartyData {
    vin: string;
    dealer: string;
    brand: string;
    typeClass: string;
    model: string;
    displacement: string;
    province: string;
    city: string;
    retailYearMonth: string;
    retailTime: string;
    color: string;
    license: string;
}

export interface ThirdPartyDataUploadData extends ThirdPartyData {
    filename: string;
    createdAt: string;
}
export interface DataListResponse<T> {
    content: T[];
    totalElements: number;
}

export interface ThirdPartyDataError extends ThirdPartyData {
    error: string;
}

export interface ThirdPartyDataFilename {
    filename: string;
}

export enum PublishDateVersionType {
    HISTORY,
    NEW,
}
export interface PublishDateVersion {
    date: string;
    type: PublishDateVersionType;
}

export interface AreaRuleCategory {
    id: string;
    name: string;
}
export interface AreaRule {
    applicableTypeClass?: string;
    bu?: string;
    category: string;
    focusAreaBasic: '0';
    focusAreaExtend: '0' | '1' | '2';
    focusAreaSpecial: string;
    id?: number;
    publishDate: string;
    status: '0';
    specialRule?: SpecialRule[];
}

export interface AreaRuleProvince {
    id: number;
    code: string;
    isSelected: string;
    name: string;
}

export interface AreaRuleCity {
    code: string;
    isSelected: string;
    name: string;
    provinceCode: string;
    provinceName: string;
}

export interface SpecialRule {
    selectedProvince?: string;
    selectedProvinceName?: string;
    selectedCities: string[];
    selectedCitiesName: Record<string, string>;
    cityOptions: AreaRuleCity[];
}
export interface AreaRuleParam {
    applicableTypeClass: string;
    bu: string;
    category: string;
    focusAreaBasic: '0';
    focusAreaExtend: '0' | '1' | '2';
    publishDate: string;
    focusAreaSpecial: '0';
    status: '0';
    selectCityList: {
        code: string;
        name: string;
        provinceCode: string;
        provinceName: string;
    }[];
}

export type AreaRuleParams = AreaRuleParam[];

export interface CurveSelection {
    dealerCode: string;
    dealerName: string;
    restrictedCity: string;
    year: string;
}

export interface CurveSelectionUploadError extends CurveSelection {
    error: string;
}
