
import { defineComponent, ref,watch } from 'vue'
import type { PropType } from 'vue'
import {applicableTypeClassListByBu} from '@/API/localRegistration'
export default defineComponent({
  name: 'ApplicableTypeClassSelector',
  props: {
    key: {
      type: String as PropType<string>,
      default: 'code'
    },
    bu: {
      type: String as PropType<string>,
      default: '',
      required: true
    }
  },
  setup(props) {
    const typeClasses = ref([])
    watch(()=> props.bu, (newBu: any) => {
      if (newBu!==''){
        applicableTypeClassListByBu({bu: newBu}).then((res: any) => {
          typeClasses.value = res
        })
      }
    }, {immediate: true})

    return {
      typeClasses
    }
  }
})
